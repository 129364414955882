<template>
  <div class="picker">
    <van-field
      v-model="value"
      is-link
      readonly
      name="picker"
      placeholder="请选择"
      @click="show = true"
    />
    <van-popup v-model:show="show" position="bottom">
      <van-picker
        :columns="columns"
        :title="title"
        :columns-field-names="columnsFieldNames"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'MyPicker',
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    columnsFieldNames: {
      type: Object,
      default() {
        return {}
      },
    },
    title: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      show: false,
      value: '',
    }
  },
  methods: {
    onConfirm(item) {
      if (Object.keys(this.columnsFieldNames).length > 0) {
        this.value = item[this.columnsFieldNames.text]
      } else {
        this.value = item
      }
      this.$emit('confirm', item)
      this.show = false
    },
  },
}
</script>

<style lang="scss" scoped>
.picker {
  width: 100%;
  height: 100%;
  /deep/ .van-field {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    background-color: transparent;
  }
}
</style>
