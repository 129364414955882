<template>
  <div>
    <div class="verify-input">
      本次建筑垃圾核销张数：
      <div class="my-picker">
        <my-picker :columns="list" @confirm="onConfirm" />
      </div>
    </div>
    <div class="verify-btn">
      <van-button
          type="primary"
          block
          size="small"
          round
          @click="onSubmit"
      >确定</van-button>
    </div>
  </div>
</template>

<script>
import MyPicker from '@/components/picker/index.vue'
import {postCertTrashVerifyApi} from '@/api'
import {getUrlParams} from '@/utils'
export default {
  name: "verify",
  components: {
    MyPicker
  },
  data() {
    return {
      user_info: [],
      list: ['1张', '2张', '3张', '4张', '5张', '6张', '7张', '8张', '9张', '10张'],
      num: 0
    }
  },
  mounted() {
    if (window.localStorage.getItem('user_info')) {
        // 运营
        this.user_info = JSON.parse(window.localStorage.getItem('user_info'))
    } else {
      // 去登录
      this.$toast({
        type: 'fail',
        message: '您还未登录！',
        onClose: () => {
          this.$router.push('/login')
        }
      })
    }
  },
  methods: {
    onConfirm(num) {
      this.num = num.slice(0, -1)
    },
    onSubmit() {
      postCertTrashVerifyApi({
        access_token: this.user_info.token,
        sid: getUrlParams('sid'),
        num: this.num
      }).then(res => {
        if (res.code === 0) {
          this.$toast({
            type: 'success',
            message: '核销成功！',
            onClose: () => {}
          })
        } else {
          this.$toast({
            type: 'fail',
            message: res.msg,
            onClose: () => {
              this.$router.push('/login')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.verify-input {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}
.verify-btn {
  padding: 100px 150px;
}
</style>